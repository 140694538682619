<!-- 商超欠款 -->

<template>
  <div class="main">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="shopName" placeholder="搜索商超名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-select v-model="feeStatus" placeholder="选择缴费状态" @change="onSearch" clearable>
          <el-option v-for="item in feeStatusOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelectData" clearable
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopName" label="商超名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="orderNum" label="缴费单号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="confirmTime" label="结算时间"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip label="结算周期">
            <template slot-scope="scope">
              <span>{{ scope.row.startDate + '~' + scope.row.endDate }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="orderAmount" label="欠款总额(元)"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="status" label="缴费情况">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">未缴费</span>
              <span v-if="scope.row.status == 1">已缴费</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="payTime" label="缴费时间"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="200" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      shopName: '', // 商超名称
      // 缴费状态
      feeStatus: '',
      feeStatusOptions: [{
        value: '0',
        label: '未缴费'
      },
      {
        value: '1',
        label: '已缴费'
      }
      ],
      dataValue: '', // 时间区间
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.selectSettlementArrearsPay();
  },
  methods: {
    // 获取列表
    selectSettlementArrearsPay() {
      this.loading = true;
      let params = {
        shopName: this.shopName || null, // 商超名称
        payStatus: this.feeStatus || null, // 缴费状态
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      // 时间区间
      if (this.dataValue && this.dataValue.length === 2) {
        params.startDate = this.dataValue[0];
        params.endDate = this.dataValue[1];
      } else {
        params.startDate = null;
        params.endDate = null;
      }

      this.$axios.post(this.$api.selectSettlementArrearsPay, params
      )
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 查看
    handleCheck(row) {
      this.$router.push({
        path: '/settlement/detail/supermarketsDebtDetail',
        query: {
          id: row.id,
          startDate: row.startDate,
          endDate: row.endDate,
          shopId: row.shopId,
        }
      })
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.selectSettlementArrearsPay();
    },

    // 时间搜索
    handleSelectData(value) {
      this.dataValue = value;
      this.onSearch();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.onSearch();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.selectSettlementArrearsPay();
    },
  }

}
</script>

<style lang="scss" scoped>
.main {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 220px;
      margin-left: 20px;
    }

    .el-select {
      margin-left: 20px;
    }

    ::v-deep .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    ::v-deep .el-range-input {
      background-color: #f2f2f2;

    }
  }
}
</style>
